<template>
  <div>
    <div class="main">
      <div class="grid">
        <div class="grid-item" :class="[active === '' ? 'active' :' ']" @click="onTab('')">
          <div class="grid-item-label">作业总数</div>
          <div class="grid-item-value">{{ grids.homework_count }}</div>
        </div>
        <div class="grid-item" :class="[active === 0 ? 'active' :' ']" @click="onTab(0)">
          <div class="grid-item-label">未完成</div>
          <div class="grid-item-value">{{ grids.not_finish_count }}</div>
        </div>
        <div class="grid-item" :class="[active === 1 ? 'active' :' ']" @click="onTab(1)">
          <div class="grid-item-label">未点评</div>
          <div class="grid-item-value">{{ grids.comment_count }}</div>
        </div>
        <div class="grid-item" :class="[active === 2 ? 'active' :' ']" @click="onTab(2)">
          <div class="grid-item-label">已点评</div>
          <div class="grid-item-value">{{ grids.finish_count }}</div>
        </div>
      </div>
      <!--  -->
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div class="list">
          <div v-for="item in list" :key="item.homework_id" class="list-item" @click="onCode(item.homework_id)">
            <div v-if="item.homework_status == 2" style="position: relative;width: 100%;height: 100%;">
              <div class="list-item-hd">
                <div class="list-item-hd-title van-ellipsis"> {{ item.homework_title }}</div>
                <van-icon name="ellipsis" />
              </div>
              <div class="list-item-bd">
                <div class="list-item-bd-time">完成时间：{{ item.homework_finish_at }}</div>
                <div class="list-item-action">
                  <van-rate
                    :value="item.homework_comment_rate"
                    size="12"
                    readonly
                    allow-half
                    void-icon="star"
                    void-color="#eee"
                    :color="globalData.theme.color1"
                  />
                </div>
              </div>
              <div style="position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                text-align:right;">
                <wx-open-launch-weapp
                    id="launch-btn"
                    :path='item.homework_path'
                    username="gh_704767f97fd7"
                    @error="errorOpen"
                  >
                    <script type="text/wxtag-template">
                      <div style="
                      width:300px;
                      height:60px;
                      "
                      ></div>
                    </script>
                  </wx-open-launch-weapp>
              </div>
            </div>

            <div v-else-if="item.homework_status == 1">
              <div class="list-item-hd">
                <div class="list-item-hd-title"> {{ item.homework_title }}</div>
                <van-icon name="ellipsis" />
              </div>
              <div class="list-item-bd">
                <div class="list-item-bd-time">完成时间：{{ item.homework_finish_at }}</div>
                <div class="list-item-action">
                  <wx-open-launch-weapp
                    id="launch-btn"
                    :path='item.homework_path'
                    username="gh_704767f97fd7"
                    @error="errorOpen"
                  >
                    <script type="text/wxtag-template">
                      <div style="
                      color: #fff;
                      background-color: #00DE93;
                      border: 1px solid #00DE93;
                      padding: 0 7px;
                      font-size: 12px;
                      width:45px;
                      height:28px;
                      text-align:center;
                      border-radius: 2px;
                      cursor: pointer;
                      line-height:28px;"
                      >
                        待点评
                      </div>
                    </script>
                  </wx-open-launch-weapp>
                  <!-- <van-button type="warning" size="small">待点评</van-button> -->
                </div>
              </div>
            </div>

            <div v-else>
              <div class="list-item-hd">
                <div class="list-item-hd-title"> {{ item.homework_title }}</div>
                <van-icon name="ellipsis" />
              </div>
              <div class="list-item-bd">
                <div class="list-item-bd-time">布置时间：{{ item.homework_date }}</div>
                <div class="list-item-bd-integral">+{{ item.homework_integral }}学分</div>
                <div class="list-item-action">
                  <wx-open-launch-weapp
                    id="launch-btn"
                    :path='item.homework_path'
                    username="gh_704767f97fd7"
                    @error="errorOpen"
                  >
                    <script type="text/wxtag-template">
                      <div style="
                      color: #fff;
                      background-color: #00DE93;
                      border: 1px solid #00DE93;
                      padding: 0 7px;
                      font-size: 12px;
                      width:45px;
                      height:28px;
                      text-align:center;
                      border-radius: 2px;
                      cursor: pointer;
                      line-height:28px;"
                      >
                        去完成
                      </div>
                    </script>
                  </wx-open-launch-weapp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <van-popup v-model="show" position="center">
        <div class="qrcode">
          <van-image class="qrcode-img" :src="currentCode" />
          <div class="qrcode-title">长按识别小程序二维码</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Popup, Grid, GridItem, Rate } from 'vant'

export default {
  name: 'StudentHomework',
  components: {
    [Popup.name]: Popup,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Rate.name]: Rate
  },
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1,
      show: false,
      currentCode: '',
      grids: { 'homework_count': 0, 'finish_count': 0, 'not_finish_count': 0, 'comment_count': 0 },
      active: ''
    }
  },
  methods: {
    onTab(active) {
      this.active = active
      this.pageCurrent = 1
      this.onPage()
    },
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.homework_index({ 'per-page': this.pageSize, page: this.pageCurrent, filter_type: this.active }).then(res => {
        this.grids = res._data
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    },
    onCode(homework_id) {
      return false
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      this.$api.homework_detail({ homework_id }).then(res => {
        this.$toast.clear()
        this.currentCode = res.data.wxapp_code
        this.show = true
      })
    },
    errorOpen(a,b){
      console.log(a,b)
    }
  },
}
</script>
<style lang="less" scoped>

    .container{
      padding: 10px 0;
    }
    .list{

    }
    .list-item{
      box-sizing: border-box;
      width: 100%;
      padding: 10px 15px;
      background: #fff;
      margin-bottom: 10px;
      &-hd{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &-title{
          flex: 1;
        }
      }
      &-bd{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-avatar{
          width: 30px;
          height: 30px;
          margin-right: 5px
        }
        &-time{
          font-size: 12px;
          color: #666;
          margin-right: 5px;
        }
        &-integral{
          font-size: 12px;
          color: #e4b055;
          flex: 1;
        }
      }
        &-action{
          flex: 1;
          text-align: right;
        }
    }

    .qrcode{
      padding: 10px 15px;
      border-radius: 4px;
      &-img{
        width: 200px;
        height: 200px;
      }
      &-title{
        font-size: 16px;
        line-height: 2;
        color: #767676;
        text-align: center;
      }
    }

    .grid{
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #fff;
      margin-bottom: 20px;
      &-item{
        flex: 1;
        text-align: center;
        &-label{
          font-weight: bold;
          font-size: 16px;
          color: #333;
          margin-bottom: 10px;
        }
        &-value{
          color: #666;
          font-size: 14px;
        }
      }
     .active{
        color: #00DE93;
         .grid-item-label{
          color: #00DE93;
        }
         .grid-item-value{
          color: #00DE93;
        }
      }
    }
</style>

